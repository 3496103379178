import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/atoms/Button"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="cc-section--horizontal-space pt-10 md:pt-20 pb-28">
      <h1 className="capitalize text-center font-bold text-8xl lg:text-9xl !leading-[1.4em] text-[#d0f05a]">
        404
      </h1>
      <p class="mx-auto mb-6 text-center text-lg md:text-4xl font-medium !leading-[1.5em] text-[#0A1E46]">
        Page not found!
      </p>
      <div className="text-center">
        <Button to={"/"} href={"/"} text={"Homepage"} ec={`secondary-btn`} />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
